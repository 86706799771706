<template>
  <div class="tfoms__params">
    <div class="tfoms__params-header">Параметры</div>

    <div class="tfoms__params-wrap">
      <div class="mb-3">
        ПЕРИОД:
        <span class="primary--text">{{ queryParams.params.Year }} год</span>
      </div>

      <m-select
        v-model="selectPeriod"
        :items="optionsPeriod"
        placeholder="Помесячно"
        itemValue="id"
        hide-details
      ></m-select>

      <div style="margin-top: 35px" class="mb-5">
        УСЛОВИЯ И ВИДЫ ОКАЗАНИЯ МП
      </div>
      <div class="tfoms__params-levels">
        <m-autocomplete
          @input="
            updateQueryParams1({ organizationId: $event, periodNum: null })
          "
          :items="optionsOrganization"
          :value="queryParams1.params.organizationId"
          item-text="Name"
          placeholder="Медицинская организация"
          hide-details
          return-object
        ></m-autocomplete>
        <m-autocomplete
          @input="
            this.updateQueryParams1({ indicatorId: $event, periodNum: null })
          "
          :value="queryParams1.params.indicatorId"
          :items="auditIndicators"
          item-text="Name"
          placeholder="Показатель оценки качества"
          hide-details
          return-object
        ></m-autocomplete>
      </div>
    </div>
  </div>
</template>
<script>
import { sortBy } from "@/utils/Functions";
import kibmdTab from "../mixins/kibmdTab";
import OrganizationServive from "@/services/OrganizationService.js";
import { mapActions } from "vuex";
export default {
  components: {
    mAutocomplete: () => import("@/components/base/inputs/mAutocomplete"),
    mSelect: () => import("@/components/base/inputs/mSelect"),
  },
  mixins: [kibmdTab],
  props: {
    value: {
      type: Number,
      default: 1,
    },
    currentCare: {
      type: Object,
      default: () => {},
    },
    healthCares: {
      type: Array,
      default: null,
    },
    tfomsMore: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      selectPeriod: 0,
      optionsPeriod: [
        { id: 0, value: "Помесячно" },
        { id: 1, value: "Поквартально" },
        { id: 2, value: "Полугодие" },
      ],
      optionsOrganization: [],
      auditIndicators: [],
    };
  },
  computed: {},
  watch: {},
  async mounted() {
    this.optionsOrganization = sortBy(
      (await OrganizationServive.relatedOrganizations.get()).data,
      "Name"
    );

    this.auditIndicators = await this.LOAD_AUDIT_INDICATORS();
  },
  methods: {
    ...mapActions(["LOAD_AUDIT_INDICATORS"]),
  },
};
</script>
<style lang="scss">
.tfoms__params {
  width: 389px;
  height: 709px;
  background-color: var(--v-white-base);
  border-radius: 10px;
  font-size: 14px;
  color: var(--v-blue-grey-base);

  &-levels {
    input {
      font-size: 14px;
    }
  }

  .v-input {
    padding-top: 0;
  }
  .v-select__slot input,
  .v-select__selection {
    color: var(--v-primary-base);
    margin-bottom: 0;
    font-size: 14px;
  }

  &-wrap {
    padding: 35px 30px 35px 40px;
  }

  &-header {
    height: 78px;
    background-color: var(--v-blue-grey-lighten2);
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .v-input--radio-group {
    padding: 0 20px 20px 20px;
  }
  .v-list-item:not(:last-child) {
    border-bottom: solid 1px var(--v-grey-base);
  }
}
</style>
